import { Subject, of, switchMap } from 'rxjs';

import { AvgDetailsRatings } from '@app/core/models/avg-details-ratings.model';
import { Freight } from '@app/core/models/freight.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginateRequest } from '@app/core/models/paginate-request.model';
import { RateFreight } from '@app/core/models/rate-freight.model';
import { RatingResponse } from '@app/core/models/rating-response.model';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@env/environment';

@Injectable()
export class RatingsService {
	private URL: string = environment.apiUrl + 'ratings/';
	private reloadRatingPendingSubject = new Subject<void>();
	reloadRatingsPendings$ = this.reloadRatingPendingSubject.asObservable();

	private updateRatingSubject = new Subject<Freight>();
	updateRating$ = this.updateRatingSubject.asObservable();

	constructor(private http: HttpClient) {}

	getRatings(paginate: PaginateRequest) {
		return this.http
			.get(
				`${this.URL}get-ratings?page=${paginate.page}&size=${paginate.size}&uuid=${paginate.uuid}`,
			)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<RatingResponse[]>;
					return of(res.data);
				}),
			);
	}

	rateFreight(request: RateFreight) {
		return this.http.post(`${this.URL}rate-freight`, request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	updateRateFreight(request: RateFreight) {
		return this.http.post(`${this.URL}update-rate-freight`, request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	getAvgDetails(uuid: string = '') {
		return this.http.get(`${this.URL}get-avg-details?uuid=` + uuid).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<AvgDetailsRatings>;
				return of(res.data);
			}),
		);
	}

	getPendingsRatings(uuid: string = '', page: number, size: number) {
		return this.http
			.get(`${this.URL}pendings-ratings?uuid=${uuid}&page=${page}&size=${size}`)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<Freight[]>;
					return of(res.data);
				}),
			);
	}

	getAllRatingsMade(uuid: string = '', page: number, size: number) {
		return this.http
			.get(`${this.URL}all-ratings-made?uuid=${uuid}&page=${page}&size=${size}`)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<Freight[]>;
					return of(res.data);
				}),
			);
	}

	reloadPendings() {
		this.reloadRatingPendingSubject.next();
	}

	updateRating(currentRatings: Freight) {
		this.updateRatingSubject.next(currentRatings);
	}
}
